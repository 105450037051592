import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Controller, ControllerProps, FieldValues, Path, UseFormReturn } from "react-hook-form";
import { useOptionalForm } from "../../../hooks/useOptionalForm";
import { NativeDateControl, NativeDateControlJSSClassKey, NativeDateControlProps } from "../NativeDateControl";

export type RhfNativeDateControlJSSClassKey = NativeDateControlJSSClassKey;

export type RhfNativeDateControlProps<FV extends FieldValues = FieldValues, TN extends Path<FV> = Path<FV>> = Omit<
  NativeDateControlProps,
  "value" | "onChange" | "TextFieldProps" | "name"
> & {
  classes?: Partial<ClassNameMap<NativeDateControlJSSClassKey>>;
  name: TN;
  form?: UseFormReturn<FV, object>;
  TextFieldProps?: Partial<Omit<NativeDateControlProps["TextFieldProps"], "name">>;
  ControllerProps?: Partial<Omit<ControllerProps<FV, TN>, "name" | "control" | "render">>;
};

export const RhfNativeDateControl = <FV extends FieldValues = FieldValues, TN extends Path<FV> = Path<FV>>(
  props: RhfNativeDateControlProps<FV, TN>
) => {
  let { name, form, ControllerProps, DateTimePickerProps, helperText, ...NativeDateControlRest } = props;
  const { error } = DateTimePickerProps || {};
  form = useOptionalForm(form);
  form.watch(name);

  return (
    <Controller<FV, TN>
      name={name}
      control={form.control}
      render={({ field: { value, onChange, onBlur }, fieldState }) => (
        <NativeDateControl
          value={value}
          onChange={e => {
            return onChange(e)
          }}
          TextFieldProps={{
            onBlur,
          }}
          error={!!error || !!fieldState.error}
          helperText={!!fieldState.error?.["message"] ? fieldState.error?.["message"] : helperText}
          {...NativeDateControlRest}
        />
      )}
      {...ControllerProps}
    />
  );
};

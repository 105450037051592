import { useEffect } from "react";
import { FieldValues, useFormContext, UseFormReturn } from "react-hook-form";

export function useOptionalForm<FV extends FieldValues = FieldValues>(
  form: UseFormReturn<FV, object> | undefined
): UseFormReturn<FV, object> {
  useEffect(() => {
    const lastForm = form;
    return () => {
      if (form !== lastForm) throw new Error("form cannot change");
    };
  }, [form]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return form || useFormContext();
}

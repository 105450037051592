import { Box, makeStyles, TextField, TextFieldProps } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { DateTimePickerProps } from "@material-ui/pickers";
import { parseDate, ParsingOption as ChronoParsingOption } from "chrono-node";
import clsx from "clsx";
import { differenceInMinutes, format as formatDate } from "date-fns";
import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from "react";
import { DATE_TIME_DISPLAY_FORMAT, isDateToday } from "../../utils/dates";
import { mergeRefs } from "../../utils/react";
import { DateTimePicker } from "./DateTimePicker";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    textField: {},
  }),
  {
    classNamePrefix: "NativeDateControl",
  }
);

export type NativeDateControlJSSClassKey = keyof ReturnType<typeof useStyles>;
export type NativeDateControlOnChangeHandler = (value: Date) => void | Promise<void>;

export type NativeDateControlProps = {
  classes?: Partial<ClassNameMap<NativeDateControlJSSClassKey>>;
  className?: string;
  label?: TextFieldProps["label"];
  helperText?: TextFieldProps["helperText"];
  variant?: TextFieldProps["variant"];
  placeholder?: TextFieldProps["placeholder"];
  error?: TextFieldProps["error"];
  value: Date;
  format?: string;
  chronoOptions?: ChronoParsingOption;
  refDate?: Date;
  disabled?: boolean;
  dayMode?: boolean;
  defaultDatePickerHour?: number;
  defaultDatePickerValue?: Date | null;
  disablePast?: boolean;
  disableFuture?: boolean;
  name?: string;
  TextFieldProps?: Partial<
    Omit<
      TextFieldProps,
      "value" | "onChange" | "name" | "disabled" | "label" | "helperText" | "variant" | "placeholder" | "error"
    >
  >;
  DateTimePickerProps?: Partial<
    Omit<DateTimePickerProps, "value" | "onChange" | "anchorEl" | "name" | "disablePast" | "disableFuture">
  >;
  onChange: NativeDateControlOnChangeHandler;
};

export const NativeDateControl: VFC<NativeDateControlProps> = ({
  className,
  classes: extClasses,
  TextFieldProps,
  format = DATE_TIME_DISPLAY_FORMAT,
  onChange,
  disabled,
  label,
  name,
  helperText,
  dayMode,
  defaultDatePickerHour,
  defaultDatePickerValue,
  error,
  chronoOptions,
  variant,
  refDate,
  placeholder,
  disableFuture = false,
  disablePast = true,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const { inputRef: muiInputRef, onBlur, ...textFieldPropsRest } = TextFieldProps || {};
  const classes = useStyles({
    classes: extClasses,
  });

  const dateStr = useMemo(() => {
    if (!value) return "";

    if (!dayMode) {
      return Math.abs(differenceInMinutes(value, new Date())) <= 7.5 ? "now" : formatDate(value, format);
    } else {
      return isDateToday(value) ? "Today" : formatDate(value, format);
    }
  }, [dayMode, value, format]);

  const [inputVal, setInputVal] = useState(dateStr);

  /**
   * If a new date comes in from a parent we want
   * to set the text field value to it IMMEDIATELY
   */
  useEffect(() => {
    setInputVal(dateStr);
  }, [dateStr]);

  /**
   * general change handler for all cases where a
   * Date object can be created or found
   */
  const handleChange = useCallback(
    (date: Date) => {
      date = new Date(date);

      if (defaultDatePickerHour) {
        date.setHours(defaultDatePickerHour);
        date.setMinutes(0);
        date.setSeconds(0);
      }

      onChange?.(date);
    },
    [defaultDatePickerHour, onChange]
  );

  /**
   * Keydown handler for TextField.  Enter routes
   * directly to blur handler.
   */
  const handleTFKeydown = useCallback<KeyboardEventHandler<HTMLInputElement>>((e) => {
    switch (e.key) {
      case "Enter":
        (e.target as HTMLInputElement).blur();
        break;
    }
  }, []);

  /**
   * Blur handler for TextField.  Tries to parse text
   * to date, if it fails it sets the text field back
   * to the last valid value, or
   * defaultDatePickerValue if available
   */
  const handleTFBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      try {
        handleChange(parseDate(e.target.value, refDate, chronoOptions));
      } catch {
        handleChange(defaultDatePickerValue || value);
      }

      onBlur?.(e);
    },
    [chronoOptions, defaultDatePickerValue, handleChange, onBlur, refDate, value]
  );

  /**
   * Change handler for TextField.  Only updates
   * internal state.  Change happens when field
   * is blurred.
   */
  const handleTFChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setInputVal(e.target.value);
  }, []);

  /**
   * Change handler for DateTimePicker.  Value already
   * comes back as date so this one's easy.
   */
  const handleDTPOnChange = useCallback(
    (name: string, date: Date) => {
      handleChange(date);
    },
    [handleChange]
  );

  return (
    <Box className={clsx(classes.root, className)}>
      <TextField
        className={classes.textField}
        disabled={disabled}
        label={label}
        helperText={helperText}
        inputRef={mergeRefs(inputRef, muiInputRef)}
        onKeyDown={handleTFKeydown}
        onBlur={handleTFBlur}
        variant={variant}
        value={inputVal}
        error={error}
        placeholder={placeholder}
        onChange={handleTFChange}
        name={name}
        {...textFieldPropsRest}
      />
      <DateTimePicker
        anchorEl={inputRef}
        value={value}
        onChange={handleDTPOnChange}
        disablePast={disablePast}
        disableFuture={disableFuture}
      />
    </Box>
  );
};
